* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

html,
body,
#root {
  height: 100%;
  background-color: black;
  color: white;
  overflow: hidden;
}

body.hide-cursor * {
  cursor: none !important;
}

body,
nav,
h1,
h2,
h3,
h4,
ul,
ol,
menu,
p {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4 {
  font-weight: normal;
}

ul,
ol {
  list-style: none;
}

body,
input,
button,
select {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

input,
button,
select {
  -webkit-appearance: none;
  border-radius: 0;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  user-select: initial;
}

button {
  cursor: pointer;
}

button:focus {
  outline: none;
}

img {
  -webkit-user-drag: none;
}

.loading-screen {
  width: 100%;
  height: 100%;
  background-color: #212121;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading-screen svg {
  animation: spin 1s linear infinite;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading-error {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #212121;
  color: #ccc;
  padding: 0 60px;
}
.loading-error .xl {
  font-size: 24px;
}
.loading-error .xxl {
  font-size: 36px;
  margin-bottom: 0.5em;
}
.loading-error .errors {
  color: #ccc;
  padding: 0 60px;
}
.loading-error pre {
  margin: 0;
  padding: 0;
  font-family: ui-monospace, monospace;
  font-size: 12px;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  color: red;
}

.react-swipeable-view-root,
.react-swipeable-view-container,
.react-swipeable-view-slide {
  position: relative;
  height: 100%;
}

.error-fallback {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  background-color: #222;
  color: white;
  text-align: center;
}
.error-fallback__body {
  padding: 30px;
}
.error-fallback__body h1 {
  font-size: 36px;
  margin-bottom: 0.5em;
}
.error-fallback__body p {
  font-size: 24px;
}
.error-fallback__footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.error-fallback__footer p {
  color: #ccc;
}

.unavailable-slide-type {
  height: 100%;
  background-color: rgb(40, 40, 40);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
}
.unavailable-slide-type svg {
  width: 24px;
  height: 24px;
}
.unavailable-slide-type svg path {
  fill: white;
}
.unavailable-slide-type p {
  font-size: 16px;
  line-height: 130%;
  max-width: 550px;
  color: #999;
  margin-top: 10px;
}
.unavailable-slide-type p strong {
  color: white;
  display: block;
  color: white;
  margin-bottom: 10px;
}
.unavailable-slide-type p code {
  font-family: ui-monospace, monospace;
  font-size: 12px;
  background-color: rgb(30, 30, 30);
  border-radius: 3px;
  padding: 3px 5px 5px 5px;
}
